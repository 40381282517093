/* Signup Container Styles */
.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #ffecd2, #ff9a9e); /* Softer gradient */
    padding: 20px;
    box-sizing: border-box;
    color: #004d40;
}

/* Header Styles */
.signup-container h2 {
    margin-bottom: 20px;
    color: #004d40;
    font-size: 2.6rem; /* Increased font size for better visibility */
    font-weight: 700;
    text-align: center;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    letter-spacing: 1px; /* Added letter-spacing for a bit more style */
}

/* Form Styles */
.signup-container form {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Increased gap between form elements */
    width: 100%;
    max-width: 450px; /* Increased max-width for a more spacious form */
    padding: 25px;
    background-color: rgba(255, 255, 255, 0.95); /* Slightly more opaque background */
    border-radius: 12px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
    animation: fadeIn 0.5s ease-in-out;
}

/* Input Styles */
.signup-container input {
    padding: 14px;
    border: 2px solid #00796b;
    border-radius: 8px;
    outline: none;
    font-size: 1rem;
    transition: border-color 0.3s, background-color 0.3s;
    background-color: #f9f9f9;
    font-weight: 500; /* Subtle font-weight for inputs */
}

/* Focus State */
.signup-container input:focus {
    border-color: #004d40;
    background-color: #e0f7fa;
}

/* Button Styles */
.signup-container button {
    padding: 14px;
    border: none;
    background-color: #00796b;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem; /* Slightly larger button text */
    font-weight: 600;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
}

/* Button Hover Effect */
.signup-container button:hover {
    background-color: #004d40;
    transform: scale(1.05);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Added subtle box-shadow */
}

/* Disabled Button Style */
.signup-container button:disabled {
    background-color: #b2dfdb;
    cursor: not-allowed;
}

/* Google Signup Button */
.google-signup {
    background-color: #4285F4;
    color: white;
    margin-top: 12px;
    padding: 14px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
    width: 100%; /* Full width */
}

/* Google Signup Hover Effect */
.google-signup:hover {
    background-color: #357ae8;
    transform: scale(1.05);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for hover */
}

/* Error Message Styling */
.error {
    color: #d32f2f;
    font-size: 0.95rem;
    margin-bottom: 15px;
    text-align: center;
    padding: 10px;
    border: 1px solid #d32f2f;
    border-radius: 5px;
    background-color: #ffebee;
}

/* Redirect to Login Styles */
.redirect-to-login {
    text-align: center;
    margin-top: 20px;
    font-size: 1rem;
    color: #004d40;
}

.redirect-to-login p {
    margin: 0;
}

/* Link styling */
.redirect-to-login a {
    color: #00796b;
    text-decoration: underline;
    font-weight: bold;
    transition: color 0.3s, text-shadow 0.3s;
}

.redirect-to-login a:hover {
    color: #004d40;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .signup-container h2 {
        font-size: 2.2rem; /* Adjusted heading size for smaller screens */
    }

    .signup-container form {
        padding: 16px;
        max-width: 90%;
        box-shadow: none;
        border: 1px solid #e0f2f1;
    }

    .signup-container input, 
    .signup-container button, 
    .google-signup {
        font-size: 1rem;
        padding: 12px;
    }
}

/* Fade-in Animation */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
