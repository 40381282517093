/* General Styles */
.test-page-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f9fafb;
    border-radius: 12px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    color: #333;
}

/* Header Styling */
.test-page-header {
    text-align: center;
    margin-bottom: 30px;
}

.test-page-header h1 {
    font-size: 2.8rem;
    color: #1e293b;
    margin-bottom: 15px;
    letter-spacing: 0.5px;
    font-weight: 700;
}

.test-page-header p {
    font-size: 1.2rem;
    color: #64748b;
    margin-top: 0;
}

/* Test and Difficulty Selection */
.test-selection, .difficulty-selection {
    text-align: center;
}

.test-options button, .difficulty-options button {
    padding: 16px 32px;
    margin: 12px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.test-options button:hover, .difficulty-options button:hover {
    background-color: #45a049;
    transform: translateY(-4px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Progress Bar */
.progress-bar-container {
    margin-top: 20px;
    text-align: center;
}

progress {
    width: 100%;
    height: 18px;
    margin-bottom: 10px;
    border-radius: 10px;
    appearance: none;
}

progress::-webkit-progress-bar {
    background-color: #e0e0e0;
    border-radius: 10px;
}

progress::-webkit-progress-value {
    background-color: #4CAF50;
    border-radius: 10px;
}

/* Timer */
.timer {
    text-align: center;
    font-size: 1.3rem;
    margin-top: 20px;
    font-weight: bold;
    color: #f1260f;
    letter-spacing: 1px;
}

/* Questions Section */
.test-questions {
    margin-top: 40px;
}

.question-box {
    margin-bottom: 20px;
    padding: 24px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.question-box.correct {
    background-color: #d1fad3; /* Light Green for correct */
    border-left: 6px solid #4CAF50;
}

.question-box.incorrect {
    background-color: #fbe9e7; /* Light Red for incorrect */
    border-left: 6px solid #f44336;
}

.question-box:hover {
    transform: translateY(-4px);
}

/* Options Section */
.options-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.option {
    padding: 14px 20px;
    background-color: #f7fafc;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
}

.option:hover {
    background-color: #e3e8ef;
    transform: translateY(-2px);
}

.option input[type="radio"] {
    display: none;
}

.option label {
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.option label:hover {
    background-color: #e0e0e0;
    transform: translateY(-2px);
}

/* Highlight correct answer when checked */
.option input[type="radio"]:checked + label {
    background-color: #4CAF50;
    color: white;
    transform: translateY(-2px);
}

/* Highlight wrong answers (only after submission) */
.question-box.incorrect .option input[type="radio"]:checked + label {
    background-color: #f44336;
    color: white;
}

/* Submit and Score Section */
.submit-button {
    padding: 14px 20px;
    font-size: 1.2rem;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 100%;
    max-width: 250px;
    margin: 30px auto 0;
    font-weight: 600;
}

.submit-button:hover {
    background-color: #45a049;
    transform: translateY(-4px);
}

.score-display {
    text-align: center;
    margin-top: 30px;
    font-size: 1.7rem;
    font-weight: bold;
    color: #333;
}

/* Answer Review */
.question-review {
    margin-top: 20px;
    padding: 15px;
    border: 2px solid #ddd;
    border-radius: 12px;
    background-color: #fafafa;
}

.question-review p {
    margin: 5px 0;
}

.correct {
    color: #4CAF50;
}

.incorrect {
    color: #f44336;
}

/* Responsive Design */
@media (max-width: 768px) {
    /* Adjust container padding */
    .test-page-container {
        padding: 20px;
    }

    /* Adjust header size */
    .test-page-header h1 {
        font-size: 2.3rem;
    }

    .test-page-header p {
        font-size: 1rem;
    }

    /* Stack options vertically on smaller screens */
    .options-container {
        flex-direction: column;
    }

    /* Full width for the submit button */
    .submit-button, .difficulty-options button {
        width: 100%;
    }

    .question-box {
        padding: 18px;
    }

    .timer {
        font-size: 1.1rem;
    }

    /* Adjust button padding and font-size for better readability on mobile */
    .test-options button, .difficulty-options button {
        padding: 12px 20px;
        font-size: 1rem;
    }
}

/* For Larger Screens (Desktop) */
@media (min-width: 1024px) {
    .options-container {
        flex-direction: row; /* Align options horizontally on larger screens */
        justify-content: space-between;
    }

    .question-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

/* Animation: Slide in effect for question-box */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.question-box {
    animation: slideIn 0.5s ease-out;
}

/* Added border transition for options */
.option input[type="radio"]:checked + label {
    border: 2px solid #4CAF50; /* Green border for checked option */
}

/* Adjust text contrast for readability */
.question-review p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
}
.test-selection11 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 30px;
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin: auto;
    text-align: center;
}

.test-selection11 h2 {
    font-size: 1.8rem;
    color: #fff;
    font-weight: bold;
    margin-bottom: 15px;
}

.test-selection11 button {
    background: #ffffff;
    color: #6a11cb;
    font-size: 1rem;
    font-weight: 600;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 100%;
    max-width: 300px;
}

.test-selection11 button:hover {
    background: #6a11cb;
    color: #fff;
    transform: scale(1.05);
}

@media (max-width: 600px) {
    .test-selection11 {
        width: 90%;
        padding: 20px;
    }

    .test-selection11 h2 {
        font-size: 1.5rem;
    }

    .test-selection11 button {
        font-size: 0.9rem;
        padding: 10px;
    }
}
.difficulty-selection11 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 30px;
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin: auto;
    text-align: center;
}

.difficulty-selection11 h2 {
    font-size: 1.8rem;
    color: #fff;
    font-weight: bold;
    margin-bottom: 15px;
}

.difficulty-selection11 button {
    background: #ffffff;
    color: #ff7e5f;
    font-size: 1rem;
    font-weight: 600;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 100%;
    max-width: 300px;
}

.difficulty-selection11 button:hover {
    background: #ff7e5f;
    color: #fff;
    transform: scale(1.05);
}

@media (max-width: 600px) {
    .difficulty-selection11 {
        width: 90%;
        padding: 20px;
    }

    .difficulty-selection11 h2 {
        font-size: 1.5rem;
    }

    .difficulty-selection11 button {
        font-size: 0.9rem;
        padding: 10px;
    }
}
.test-questions11 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 30px;
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 700px;
    margin: auto;
    text-align: center;
}

.test-questions11 h2 {
    font-size: 1.8rem;
    color: #fff;
    font-weight: bold;
    margin-bottom: 15px;
}

.question-box11 {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    text-align: left;
}

.question-box11 p {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
}

.question-box11 label {
    display: flex;
    align-items: center;
    background: #f1f1f1;
    padding: 10px;
    border-radius: 6px;
    margin: 5px 0;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.question-box11 label:hover {
    background: #dce9ff;
}

.question-box11 input[type="radio"] {
    margin-right: 10px;
    accent-color: #0072ff;
    transform: scale(1.2);
}

@media (max-width: 600px) {
    .test-questions11 {
        width: 90%;
        padding: 20px;
    }

    .test-questions11 h2 {
        font-size: 1.5rem;
    }

    .question-box11 {
        padding: 15px;
    }

    .question-box11 p {
        font-size: 1rem;
    }

    .question-box11 label {
        font-size: 0.9rem;
        padding: 8px;
    }
}
/* Submit Button */
.submit-button11 {
    background: linear-gradient(135deg, #ff512f, #dd2476);
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
}

.submit-button11:hover {
    background: linear-gradient(135deg, #dd2476, #ff512f);
    transform: scale(1.05);
}

/* Score Display Section */
.score-display11 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    background: linear-gradient(135deg, #36d1dc, #5b86e5);
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin: 20px auto;
    text-align: center;
}

.score-display11 h2 {
    font-size: 1.8rem;
    color: #fff;
    font-weight: bold;
}

.score-display11 p {
    font-size: 1.2rem;
    color: #fff;
    font-weight: 500;
}

/* Responsive Design */
@media (max-width: 600px) {
    .submit-button11 {
        font-size: 1rem;
        padding: 10px 20px;
    }

    .score-display11 {
        width: 90%;
        padding: 15px;
    }

    .score-display11 h2 {
        font-size: 1.5rem;
    }

    .score-display11 p {
        font-size: 1rem;
    }
}


/* Header styling */
.test-page-header {
    text-align: center;
    margin-bottom: 30px;
}

.test-page-header h1 {
    font-size: 2rem;
    color: #333;
}

.test-page-header p {
    font-size: 1rem;
    color: #777;
}

/* Styling for the question box */
.question-box {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

/* Question text */
.question-box p {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Styling for the answer options */
.question-box label {
    display: block;
    padding: 8px 10px;
    margin-bottom: 8px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Style for the radio buttons */
.question-box input[type="radio"] {
    margin-right: 10px;
}

/* Correct answer styling */
.correct {
    background-color: #4CAF50; /* Green for correct answers */
    color: white;
}

/* Incorrect answer styling */
.incorrect {
    background-color: #F44336; /* Red for incorrect answers */
    color: white;
}

/* Unselected correct answer styling */
.unselected {
    background-color: #D3D3D3; /* Light gray for unselected correct answers */
    color: #333;
}

/* Submit button styling */
.submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #45a049;
}

/* Score display styling */
.score-display {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-top: 20px;
    text-align: center;
}

.score-display h2 {
    font-size: 1.5rem;
    color: #333;
}

.score-display p {
    font-size: 1rem;
    color: #777;
}

/* Test selection and difficulty selection buttons */
button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin: 5px;
}

button:hover {
    background-color: #45a049;
}
