/* Base Styles */
body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9fafc; /* Softer light background for contrast */
    color: #333; /* Neutral base text color */
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

/* Modules Container */
.modules-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Dynamic grid with minimum box width */
    gap: 20px;
    margin: 20px auto; /* Center the container */
    padding: 0 10px; /* Prevent content overflow */
    max-width: 1200px; /* Maximum width for the container */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Module Card Styles */
.module-card {
    padding: 20px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #ddd; /* Subtle border for structure */
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}

.module-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.module-card h3 {
    font-size: 1.5rem; /* Slightly smaller for better responsiveness */
    margin-bottom: 15px;
    color: #1565c0;
    font-weight: 600;
}

.module-card p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #455a64;
    line-height: 1.6;
}

/* Download Button Styles */
.download-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.download-buttons button {
    padding: 12px 25px;
    border: none;
    border-radius: 50px;
    background: linear-gradient(90deg, #42a5f5, #1e88e5);
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
}

.download-buttons button:hover {
    background: linear-gradient(90deg, #1e88e5, #0d47a1);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .modules-container {
        gap: 15px;
        padding: 0 10px;
    }

    .module-card {
        padding: 15px;
        min-height: 220px; /* Slightly reduce minimum height for smaller screens */
    }

    .download-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .download-buttons button {
        width: 100%; /* Full-width buttons for smaller screens */
    }
}

@media (max-width: 480px) {
    .modules-container {
        gap: 10px;
        padding: 0 10px;
    }

    .module-card {
        padding: 10px;
        min-height: 200px; /* Adjust height for very small screens */
    }

    .download-buttons button {
        padding: 10px 20px;
        font-size: 0.9rem;
    }
}

/* Accessibility Improvements */
a {
    color: #0d47a1;
    text-decoration: none;
    transition: color 0.3s ease;
}

a:hover,
a:focus {
    color: #1e88e5;
    text-decoration: underline;
}

button:focus,
input:focus {
    outline: none;
    border: 2px solid #42a5f5;
}
