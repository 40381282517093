/* General Styling for About Page */
.about-page {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  color: #2c3e50;
  line-height: 1.8;
}

.about-page h1,
.about-page h2 {
  color: #0056b3;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.about-page h1 {
  font-size: 3rem;
  border-left: 6px solid #0056b3;
  padding-left: 15px;
  font-weight: 700;
  background: white;
  color: linear-gradient(90deg, #0056b3, #0078d7);
  
}

.about-page h2 {
  font-size: 2.2rem;
  border-left: 4px solid #0078d7;
  padding-left: 12px;
  font-weight: 600;
}

/* Section Styling */
.about-page section {
  margin-bottom: 40px;
  background: #ffffff;
  border-radius: 15px;
  padding: 25px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.about-page section:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

/* Paragraph Styling */
.about-page p {
  font-size: 1.1rem;
  margin-bottom: 18px;
  color: #4b4b4b;
  line-height: 1.9;
}

/* List Styling */
.about-page ul {
  margin: 20px 0;
  padding: 0 25px;
  list-style-type: none;
}

.about-page ul li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 1rem;
  color: #555;
}

.about-page ul li::before {
  content: "\2714"; /* Unicode for a checkmark */
  position: absolute;
  left: 0;
  color: #0078d7;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Call-to-Action */
.about-call-to-action {
  text-align: center;
  font-size: 1.3rem;
  color: #333;
  font-weight: bold;
  padding: 25px;
  border-top: 2px solid #0078d7;
  margin-top: 40px;
  background: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.about-call-to-action p {
  margin-bottom: 20px;
  color: #333;
}

.about-call-to-action:hover {
  background: #eaf3ff;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Button Styling */
.about-call-to-action button {
  background: linear-gradient(90deg, #0056b3, #0078d7);
  border: none;
  color: #ffffff;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.about-call-to-action button:hover {
  background: linear-gradient(90deg, #0078d7, #0056b3);
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-page h1 {
    font-size: 2.5rem;
  }

  .about-page h2 {
    font-size: 2rem;
  }

  .about-page p {
    font-size: 1rem;
  }

  .about-page ul li {
    font-size: 1rem;
  }

  .about-call-to-action {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .about-page h1 {
    font-size: 2rem;
  }

  .about-page h2 {
    font-size: 1.7rem;
  }

  .about-page p {
    font-size: 0.95rem;
  }

  .about-page ul li {
    font-size: 0.95rem;
  }

  .about-call-to-action {
    font-size: 1rem;
    padding: 20px;
  }
}
.qr-code {
  max-width: 200px; /* Adjust the size */
  margin: 20px auto;
  display: block;
}
