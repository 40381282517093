/* Global Reset and Box-Sizing */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Body Styling */
body {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    background: #f9fafb; /* Soft off-white background */
    color: #111827; /* Deep gray for text */
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

/* Headline Message */
.headline-message {
    background: linear-gradient(135deg, #1e3a8a, #2563eb); /* Gradient blue background */
    color: #ffffff; /* White text */
    padding: 30px 40px;
    text-align: center;
    border-radius: 20px;
    margin: 40px auto;
    font-size: 2.2rem;
    font-weight: 700;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15); /* Deeper shadow */
    letter-spacing: 1.5px;
    animation: fadeInDown 1s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
    max-width: 90%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.headline-message:hover {
    transform: translateY(-5px);
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
}

/* Branch Section */
.branch {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 20px;
    background: #ffffff; /* Pure white background */
    border-radius: 24px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-width: 1200px;
    margin: 0 auto 60px;
    overflow: hidden;
    color: #111827; /* Deep gray text */
}

.branch h2 {
    font-size: 4.5rem;
    font-weight: 900;
    margin-bottom: 40px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: transparent;
    background: linear-gradient(135deg, #1e3a8a, #3b82f6); /* Gradient text */
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 1s ease-in-out;
    padding-bottom: 15px;
    border-bottom: 4px solid #3b82f6; /* Light blue underline */
}

.branch p {
    font-size: 1.8rem;
    margin-bottom: 60px;
    text-align: center;
    color: #4b5563; /* Medium gray text */
    max-width: 800px;
    line-height: 1.8;
    font-weight: 500;
}

/* Semester List Grid */
.semester-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Flexible layout */
    gap: 60px; /* Spacing between boxes */
    width: 100%;
    padding: 0;
    list-style: none;
    animation: fadeInUp 1s ease-in-out;
    justify-content: center;
}

/* Semester Card */
.semester-list li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.semester-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 340px;
    width: 340px; /* Square shape */
    background: #ffffff; /* White card background */
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1); /* Subtle border */
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1); /* Light shadow */
    font-size: 2rem; /* Comfortable font size */
    font-weight: 700;
    text-transform: uppercase;
    color: #1e3a8a; /* Deep blue text */
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
}


.semester-card span {
    z-index: 1;
    position: relative;
    font-size: 2.2rem;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3); /* Light glow */
}

.semester-card::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
    z-index: -1;
    border-radius: 24px;
    transition: opacity 0.4s ease;
    opacity: 0;
}



/* Responsive Design */
@media (max-width: 1024px) {
    .branch h2 {
        font-size: 3.5rem;
    }

    .branch p {
        font-size: 1.6rem;
    }

    .semester-card {
        height: 300px;
        width: 300px;
        font-size: 1.8rem;
    }
}

@media (max-width: 768px) {
    .branch h2 {
        font-size: 3rem;
    }

    .branch p {
        font-size: 1.4rem;
        margin-bottom: 40px;
    }

    .semester-card {
        height: 280px;
        width: 280px;
        font-size: 1.7rem;
    }
}

@media (max-width: 480px) {
    .branch h2 {
        font-size: 2.5rem;
    }

    .branch p {
        font-size: 1.2rem;
    }

    .semester-card {
        height: 240px;
        width: 240px;
        font-size: 1.6rem;
    }
}


