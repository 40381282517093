/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

/* Body */
body {
  background-color: #f4f6f9;
  color: #333;
}

/* Loading Screen */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-container {
  text-align: center;
  color: white;
}

.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.welcome-text h1 {
  font-size: 2rem;
  font-weight: bold;
}

.welcome-text p {
  font-size: 1.2rem;
  color: #b0c4de;
}

/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f7fc;
  margin: 0;
  padding: 0;
}

h1, h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #333;
}

