/* Global Variables (CSS Custom Properties) */
:root {
    --primary-color: #007bff; /* Bright Blue for buttons */
    --secondary-color: #ffffff; /* White for card backgrounds */
    --background-color: #121212; /* Modern Black for main background */
    --text-color: #000000; /* Black for text */
    --input-border: #ced4da; /* Light Gray for borders */
    --focus-color: #0056b3; /* Darker Blue for focus */
    --shadow-light: rgba(255, 255, 255, 0.1); /* Subtle shadow for light theme */
    --shadow-dark: rgba(0, 0, 0, 0.5); /* Darker shadow for cards */
    --font-family: 'Inter', sans-serif; /* Clean and modern font */
}

/* General layout for the container */
body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
}

.calculator-container {
    max-width: 900px;
    margin: 50px auto;
    padding: 40px;
    border-radius: 12px;
    background-color: var(--secondary-color);
    box-shadow: 0 10px 20px var(--shadow-dark);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.calculator-container:hover {
    transform: translateY(-5px);
}

/* Title styling */
.calculator-container h1 {
    text-align: center;
    font-size: clamp(2.5rem, 5vw, 3rem);
    color: var(--text-color);
    margin-bottom: 35px;
    font-weight: 700;
}



/* Buttons */
.button {
    padding: 14px 24px;
    margin: 10px;
    border: none;
    border-radius: 12px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    color: var(--secondary-color);
    background-color: var(--primary-color);
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.button:hover {
    background-color: var(--focus-color);
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.button:active {
    transform: translateY(2px);
}

/* Input Fields */
.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.input-field {
    padding: 14px;
    border-radius: 8px;
    border: 1px solid var(--input-border);
    margin-bottom: 15px;
    outline: none;
    font-size: 1rem;
    box-shadow: 0 4px 8px var(--shadow-dark);
    background-color: var(--secondary-color);
    transition: border-color 0.3s, box-shadow 0.3s;
}

.input-field:focus {
    border-color: var(--focus-color);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

/* Responsive design */
@media (max-width: 768px) {
    .calculator-container {
        padding: 25px;
    }

    .button {
        width: 100%;
        margin: 8px 0;
    }

    .calculator-container h1 {
        font-size: 2.2rem;
    }

    .about-calculators h2 {
        font-size: 1.8rem;
    }

    .about-calculators p {
        font-size: 0.9rem;
    }
}

/* Calculator Section Styling */
.calculator-section {
    margin-top: 20px;
    padding: 20px;
    background-color: var(--secondary-color);
    border-radius: 12px;
    box-shadow: 0 6px 15px var(--shadow-dark);
    transition: transform 0.3s, box-shadow 0.3s;
}

.calculator-section:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px var(--shadow-dark);
}

.calculator-section h2 {
    font-size: 1.8rem;
    color: var(--text-color);
    margin-bottom: 15px;
}
/* General Text Styling */
.text-gray-700 {
    color: #4a4a4a; /* Neutral dark gray for better readability */
  }
  
  .text-sm {
    font-size: 1rem;
    line-height: 1.8; /* Improved readability */
  }
  
  .mb-4 {
    margin-bottom: 1.5rem; /* Slightly larger spacing for better content flow */
  }
  
  /* List Styling */
  .list-disc {
    list-style-type: none; /* Removes default bullets */
    padding-left: 0;
  }
  
  .list-inside {
    margin-left: 1.5rem; /* Aligns nicely with other elements */
  }
  
  .list-inside li {
    margin-bottom: 0.8rem; /* Better spacing between list items */
    position: relative;
    padding-left: 30px; /* Space for custom bullet */
  }
  
  .list-inside li::before {
    content: "🌟"; /* Modern and visually appealing custom bullet */
    position: absolute;
    left: 0;
    top: 2px;
    color: #0078d7; /* Matches the theme */
    font-size: 1.2rem;
  }
  
  /* Strong Text */
  .list-inside li strong {
    color: #0078d7; /* Consistent with the custom bullet color */
    font-weight: bold;
  }
  
  /* Paragraph Styling */
  .text-gray-700 p {
    margin-bottom: 1.5rem; /* Larger space for better readability */
    line-height: 1.8; /* Enhanced line spacing */
  }
  
  /* Links (Optional Styling for Better Look) */
  .text-gray-700 a {
    color: #0078d7;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: color 0.3s ease, border-bottom 0.3s ease;
  }
  
  .text-gray-700 a:hover {
    color: #005bb5;
    border-bottom: 1px solid #005bb5;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .text-sm {
      font-size: 0.9rem; /* Slightly smaller for smaller devices */
      line-height: 1.6;
    }
  
    .list-inside li {
      padding-left: 25px; /* Adjust for smaller screens */
    }
  
    .list-inside li::before {
      font-size: 1rem; /* Scaled down bullet size */
    }
  }
  
  @media (max-width: 480px) {
    .text-sm {
      font-size: 0.85rem; /* Smaller font for very small screens */
      line-height: 1.4;
    }
  
    .list-inside li {
      padding-left: 20px; /* Align with smaller screens */
    }
  
    .list-inside li::before {
      font-size: 0.9rem; /* Smaller bullet for better fit */
    }
  }
  