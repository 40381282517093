/* General Styles */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9fbfd;
  color: #0e0909;
  line-height: 1.6;
}

/* Container Styles */
.comment-section-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 25px;
  background: linear-gradient(145deg, #ffffff, #f0f4f8);
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}
.intro-paragraph {
  font-size: 1rem; /* Medium-sized text for readability */
  color: #4a5568; /* Neutral dark gray for contrast */
  line-height: 1.6; /* Comfortable line spacing */
  margin-top: 15px; /* Spacing from the heading */
  margin-bottom: 20px; /* Spacing to the next element */
  text-align: center; /* Centered alignment for aesthetic balance */
  max-width: 800px; /* Limit width for better readability */
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
  background: rgb(49, 44, 44); /* Subtle gradient background */
  padding: 15px 20px; /* Add padding for a polished look */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}


/* Header Styles */
.header {
  padding: 40px 20px;
  background: linear-gradient(145deg, #4299e1, #3182ce);
  color: #fff;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
}

.header .section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.header .intro-paragraph {
  font-size: 1rem;
  margin-top: 10px;
  color: #e2e8f0;
}

/* Form Styles */
.comment-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.comment-author,
.comment-textarea {
  padding: 15px;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  font-size: 1rem;
  background-color: #f7fafc;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.comment-author:focus,
.comment-textarea:focus {
  border-color: #3182ce;
  box-shadow: 0 4px 8px rgba(49, 130, 206, 0.3);
  outline: none;
}

.comment-author {
  max-width: 400px;
}

.comment-textarea {
  min-height: 120px;
  resize: vertical;
}

.submit-btn {
  align-self: flex-end;
  padding: 12px 25px;
  background: linear-gradient(145deg, #4299e1, #3182ce);
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.submit-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Comment Card Styles */
.comment-card {
  padding: 20px;
  background: linear-gradient(145deg, #ffffff, #f7f8fa);
  border-radius: 15px;
  border: 1px solid #e2e8f0;
  margin-bottom: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.comment-header strong {
  font-size: 1.2rem;
  color: #2d3748;
}

.timestamp {
  font-size: 0.9rem;
  color: #a0aec0;
}

.comment-text {
  font-size: 1rem;
  color: #4a5568;
  margin: 10px 0;
}

/* Reply Button */
.reply-btn {
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(145deg, #3182ce, #2b6cb0);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.reply-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Reply Styles */
.reply-card {
  margin-left: 30px;
  margin-top: 15px;
  padding-left: 15px;
  border-left: 3px solid #e2e8f0;
}

.reply-textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #e2e8f0;
  border-radius: 10px;
  font-size: 1rem;
  background-color: #f7fafc;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.reply-textarea:focus {
  border-color: #4299e1;
  box-shadow: 0 4px 8px rgba(66, 153, 225, 0.3);
}

.submit-reply-btn {
  margin-top: 10px;
  padding: 12px 20px;
  background: linear-gradient(145deg, #4299e1, #3182ce);
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.submit-reply-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(66, 153, 225, 0.2);
}

/* Toggle Comments Button */
.toggle-comments-btn {
  display: block;
  text-align: center;
  padding: 12px 20px;
  background: linear-gradient(145deg, #e2e8f0, #cbd5e0);
  color: #4a5568;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1rem;
  transition: transform 0.3s, box-shadow 0.3s;
}

.toggle-comments-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 600px) {
  .comment-section-container {
    margin: 10px;
    padding: 15px;
  }

  .header .section-title {
    font-size: 2rem;
  }

  .comment-form {
    gap: 12px;
  }

  .submit-btn,
  .submit-reply-btn,
  .reply-btn {
    padding: 10px 15px;
    font-size: 0.9rem;
  }
}
.comment-email {
  width: 100%; /* Full-width input */
  padding: 12px 16px; /* Padding inside the input */
  font-size: 16px; /* Font size */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  outline: none; /* Remove default outline */
  transition: all 0.3s ease; /* Smooth transitions */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.comment-email:focus {
  border-color: #007BFF; /* Blue border on focus */
  box-shadow: 0 2px 8px rgba(0, 123, 255, 0.25); /* Enhanced shadow on focus */
}

.comment-email::placeholder {
  color: #aaa; /* Light gray placeholder text */
  font-style: italic; /* Italic style for placeholder */
}

