/* Modern Navbar Styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  background: linear-gradient(135deg, rgba(31, 41, 55, 0.5), rgba(17, 24, 39, 0.5));
  backdrop-filter: blur(15px);
  color: #f9fafb;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  transition: background 0.3s ease, box-shadow 0.3s ease, padding 0.3s ease;
}

.navbar:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

/* Navbar Links */
.nav-links {
  display: flex;
  gap: 25px;
  list-style: none;
  font-size: 16px;
  font-weight: 600;
}

.nav-links li a {
  color: #f9fafb;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.nav-links li a:hover {
  color: #34d399;
  background: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Dropdown Menu */
.nav-links .dropdown {
  position: relative;
}

.nav-links .dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #111827;
  color: #f9fafb;
  min-width: 220px;
  padding: 12px 0;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px);
}

.nav-links .dropdown:hover .dropdown-content {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.nav-links .dropdown-content a {
  color: #d1d5db;
  padding: 12px 20px;
  text-decoration: none;
  display: block;
  border-radius: 6px;
  transition: background 0.3s ease, color 0.3s ease;
}

.nav-links .dropdown-content a:hover {
  background: #34d399;
  color: #fff;
}

/* Login Button */
.login-button1 {
  background-color: #f97316;
  color: #fff;
  padding: 12px 25px;
  border-radius: 50px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  box-shadow: 0 6px 15px rgba(249, 115, 22, 0.3);
}

.login-button1:hover {
  background-color: #f59e0b;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(243, 94, 22, 0.4);
}

.login-button1:active {
  background-color: #d97706;
  transform: translateY(1px);
}

/* Hamburger Menu */
.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 6px;
}

.hamburger .bar {
  width: 30px;
  height: 4px;
  background-color: #f9fafb;
  border-radius: 5px;
  transition: all 0.3s ease;
}

/* Mobile View */
@media (max-width: 768px) {
  .navbar {
    padding: 12px 25px;
  }

  .hamburger {
    display: flex;
    position: absolute;
    left: 20px;
    z-index: 1001;
  }

  .nav-links {
    position: fixed;
    top: 0;
    left: -100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.85);
    gap: 15px;
    list-style: none;
    transition: left 0.3s ease;
    z-index: 999;
  }

  .nav-links.active {
    left: 0;
  }

  .nav-links li a {
    font-size: 18px;
    padding: 10px 20px;
    color: #f9fafb;
    text-align: center;
  }

  .hamburger.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.open .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}

/* Profile Image Styling */
.profile-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.profile-photo:hover {
  transform: scale(1.1);
}



