/* General Footer Styling */
.footer {
  background-color: #1e1e2f; /* Deep dark background */
  color: #f5f5f5; /* Light text for readability */
  padding: 60px 20px;
  font-family: "Roboto", sans-serif;
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Footer Sections */
.footer-section {
  text-align: center;
  padding: 0 10px;
}

.footer-logo-section {
  text-align: left;
}

.footer-logo {
  font-size: 2.5rem;
  font-weight: bold;
  color: #3b82f6; /* Light blue for branding */
  margin-bottom: 20px;
}

.footer-description {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #d1d5db; /* Muted light gray */
  margin-top: 10px;
}

/* Quick Links */
.footer-links h4,
.footer-study-resources h4 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #3b82f6; /* Light blue */
}

.footer-links ul,
.footer-study-resources ul {
  list-style: none;
  padding: 0;
}

.footer-links li,
.footer-study-resources li {
  margin-bottom: 12px;
}

.footer-links a {
  color: #f5f5f5;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease-in-out;
}

.footer-links a:hover {
  color: #2563eb; /* Darker blue hover */
}

/* Study Resources Buttons */
.branch-card8 {
  background-color: #282846; /* Subtle dark background for buttons */
  border: none;
  color: #f5f5f5;
  padding: 12px 25px;
  border-radius: 6px;
  font-size: 1.1rem;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.branch-card8:hover {
  background-color: #3b82f6; /* Light blue */
  color: #1e1e2f;
  transform: translateY(-3px);
}

/* Social Media Section */
.footer-social h4 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #3b82f6; /* Light blue */
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}

.social-icon {
  color: #f5f5f5;
  font-size: 2rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
  color: #3b82f6; /* Light blue hover */
  transform: scale(1.2);
}

/* Back to Top Button */
.back-to-top {
  background-color: #007bff; /* Light blue */
  color: #f5f5f5;
  border: none;
  padding: 12px 30px;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}

.back-to-top:hover,
.floating-btn  {
  background-color: #2563eb; /* Darker blue */
  color: #f5f5f5;
  transform: translateY(-3px);
}

/* Footer Bottom Section */
.footer-bottom {
  text-align: center;
  margin-top: 40px;
  font-size: 1rem;
  border-top: 1px solid #333;
  padding-top: 20px;
  color: #a8a8b5;
}

.footer-bottom a {
  color: #3b82f6; /* Light blue links */
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer-bottom a:hover {
  color: #f5f5f5; /* Light text hover */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .footer-container {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 30px;
  }

  .footer-logo {
    font-size: 2.2rem;
  }

  .footer-description {
    font-size: 1rem;
  }

  .branch-card,
  .floating-btn  {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .social-icon {
    font-size: 1.8rem;
  }

  .back-to-top {
    bottom: 15px;
    right: 15px;
    padding: 10px 25px;
  }
}

@media (max-width: 768px) {
  .footer-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .footer-logo {
    font-size: 2rem;
  }

  .footer-description {
    font-size: 0.95rem;
  }

  .footer-links h4,
  .footer-study-resources h4 {
    font-size: 1.4rem;
  }

  .branch-card {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .social-icon {
    font-size: 1.5rem;
  }

  .back-to-top {
    padding: 8px 20px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .footer-logo {
    font-size: 1.8rem;
  }

  .footer-description {
    font-size: 0.85rem;
  }

  .footer-links h4,
  .footer-study-resources h4 {
    font-size: 1.3rem;
  }

  .branch-card {
    font-size: 0.8rem;
    padding: 6px 12px;
  }

  .social-icon {
    font-size: 1.2rem;
  }

  .back-to-top {
    padding: 7px 15px;
    font-size: 0.8rem;
  }
}
