/* General Styles for Contact Page */
.contact-page {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  background-color: #f8fafc;
  color: #333;
  line-height: 1.6;
}

/* Headers */
.contact-page h1, .contact-page h2, .contact-page h3 {
  color: #004aad;
  margin-bottom: 15px;
}

.contact-page h1 {
  font-size: 2.5rem;
  border-left: 6px solid #004aad;
  padding-left: 15px;
  margin-bottom: 25px;
}

.contact-page h2 {
  font-size: 2rem;
  border-left: 4px solid #004aad;
  padding-left: 12px;
}

.contact-page h3 {
  font-size: 1.5rem;
  border-left: 3px solid #004aad;
  padding-left: 10px;
}

/* Section Styling */
.contact-page section {
  margin-bottom: 30px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-page section:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

/* Links Styling */
.contact-page a {
  color: #004aad;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.contact-page a:hover {
  color: #002f7e;
  text-decoration: underline;
}

/* Social Links Styling */
.social-links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 0;
}

.social-links li {
  display: flex;
  align-items: center;
}

.social-links a {
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 600;
  color: #004aad;
  padding: 10px 15px;
  border-radius: 8px;
  background: #eef3fc;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.social-links a:hover {
  background-color: #004aad;
  color: #fff;
  transform: scale(1.05);
}

/* Address Styling */
.contact-page address {
  font-style: normal;
  color: #555;
  font-size: 1rem;
}

/* Contact Form Styling */
.contact-form {
  margin-top: 20px;
}

.contact-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #004aad;
  box-shadow: 0 0 5px rgba(0, 74, 173, 0.3);
  outline: none;
}

.contact-form button {
  background-color: #004aad;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact-form button:hover {
  background-color: #002f7e;
  transform: scale(1.05);
}

/* Map Container */
.map-container {
  margin-top: 20px;
  overflow: hidden;
  border-radius: 12px;
}

.map-container iframe {
  width: 100%;
  height: 300px;
  border: none;
}

/* Follow Us Section */
.follow-us {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.follow-us:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.follow-us h2 {
  font-size: 2rem;
  color: #004aad;
  margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-page h1 {
    font-size: 2rem;
  }

  .social-links {
    flex-direction: column;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.95rem;
  }

  .contact-form button {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .contact-page h1 {
    font-size: 1.8rem;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 8px;
    font-size: 0.9rem;
  }

  .contact-form button {
    padding: 10px;
    font-size: 0.9rem;
  }
}
