/* Base PDF Viewer Styles */
.pdf-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px; /* Comfortable padding for desktop and mobile */
    margin-top: 40px;
    background-color: #f4f8fc;
    border-radius: 20px; /* Larger radius for a smoother look */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
    max-width: 900px; /* Slightly larger max-width for better desktop experience */
    margin: 40px auto;
    transition: all 0.3s ease-in-out;
}

.pdf-viewer h2 {
    font-size: 2.2rem; /* Modern and slightly larger header font size */
    color: #2c3e50;
    margin-bottom: 16px; /* Reduced margin for space efficiency */
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    text-align: center;
}

/* PDF Frame Styles */
.pdf-frame {
    width: 100%;
    max-width: 800px;
    height: 600px; /* Comfortable height for both desktop and tablet views */
    border: 3px solid #d1d9e6;
    border-radius: 16px; /* Larger radius for a modern touch */
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
}

.pdf-frame:hover {
    transform: scale(1.05);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
}

/* Download Button Styles */
.download-button-container {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.download-button {
    padding: 14px 28px; /* Comfortable padding for desktop and mobile */
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.download-button:hover {
    background-color: #45a049;
    transform: translateY(-4px); /* Smooth lift effect */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.download-button:active {
    transform: translateY(0);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

/* QR Code Container Styles */
.qr-code-container {
    margin-top: 30px;
    padding: 16px;
    border: 3px dashed #3498db; /* Stylish dashed border */
    border-radius: 12px;
    background-color: #eaf2f8; /* Subtle blue background */
    text-align: center;
    width: 100%;
    max-width: 800px;
}

.qr-code-container h3 {
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 10px;
}

.qr-code-container p {
    font-size: 1rem;
    color: #555;
    margin-top: 10px;
}

/* QR Code Styles */
.qr-code {
    margin: 10px auto;
    padding: 10px;
    background-color: #fff; /* White background for QR visibility */
    border: 3px solid #d1d9e6;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Share Button Styles */
.qr-code-download,
.share-button {
    padding: 12px 24px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 16px;
}

.share-button:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.share-button:active {
    transform: translateY(0);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

/* Enhanced Responsive Styling */

/* Large Tablets and Laptops (1024px and below) */
@media (max-width: 1024px) {
    .pdf-viewer {
        padding: 20px;
    }

    .pdf-frame {
        height: 550px;
    }

    .qr-code-download,
    .share-button {
        font-size: 1rem;
        padding: 10px 20px;
    }
}

/* Medium Tablets and Small Laptops (768px and below) */
@media (max-width: 768px) {
    .pdf-viewer {
        padding: 16px;
    }

    .pdf-viewer h2 {
        font-size: 2rem; /* Adjusted for tablets */
    }

    .pdf-frame {
        width: 90%;
        height: 500px;
    }

    .qr-code-download,
    .share-button {
        font-size: 0.95rem;
    }

    .qr-code-container {
        padding: 12px;
    }

    .qr-code-container h3 {
        font-size: 1.6rem;
    }

    .qr-code {
        padding: 8px;
    }
}

/* Mobile Portrait (480px and below) */
@media (max-width: 480px) {
    .pdf-viewer {
        padding: 12px;
    }

    .pdf-viewer h2 {
        font-size: 1.6rem;
    }

    .pdf-frame {
        width: 100%;
        height: 400px;
    }

    .download-button,
    .share-button {
        padding: 8px 16px;
        font-size: 0.85rem;
    }

    .qr-code-container {
        padding: 8px;
    }

    .qr-code-container h3 {
        font-size: 1.4rem;
    }

    .qr-code {
        width: 100%;
        max-width: 140px;
    }
}
.qr-code-container {
    text-align: center;
    margin-top: 20px;
}

.copy-link-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.copy-link-button:hover {
    background-color: #0056b3;
}

.copy-success {
    color: green;
    font-weight: bold;
    margin-top: 10px;
}

.download-button-container {
    margin-top: 20px;
    text-align: center;
}

.file-info {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: #555;
}
