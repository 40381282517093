/* General Styles for the Subjects Section */
.subjects {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    min-height: 100vh;
    background-color: #ffffff; /* White background */
    color: #333333; /* Dark text color for better readability */
    font-family: 'Poppins', sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    gap: 40px;
    border-radius: 20px;
    box-shadow: 0 12px 36px rgba(0, 0, 0, 0.15); /* Reduced shadow */
}

/* Subject Title */
.subjects h2 {
    font-size: 3.6rem;
    margin: 20px 0;
    text-transform: uppercase;
    font-weight: 700;
    color: #333333; /* Dark text for contrast */
    letter-spacing: 2px;
    padding-bottom: 15px;
    position: relative;
    text-align: center;
    animation: fadeIn 1.2s ease-out;
}

.subjects h2::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 5px;
    background: linear-gradient(to right, #9c4fff, #6a52db); /* Vibrant purple gradient */
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50px;
}

/* Grid Layout for Subject Boxes */
.subjects ul {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    width: 100%;
}

/* Subject Box Styling */
.subject-box {
    background: rgba(255, 255, 255, 0.9); /* Slight transparency */
    backdrop-filter: blur(8px); /* Softer blur effect */
    padding: 35px;
    border-radius: 18px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1); /* Lighter shadow */
    position: relative;
    overflow: hidden;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #333333; /* Dark text for readability */
    height: 360px;
    border: 1px solid rgba(0, 0, 0, 0.1); /* Light border */
}

/* Hover Effect for Subject Box */
.subject-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15); /* Enhanced hover effect */
}

/* Gradient Border on Hover */
.subject-box::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    border-radius: 20px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.subject-box:hover::before {
    opacity: 1;
    border: 2px solid #9c4fff; /* Vibrant purple border */
}

/* Subject Box Heading */
.subject-box h3 {
    font-size: 2.4rem;
    margin: 0;
    font-weight: 600;
    color: #9c4fff; /* Soft purple for headings */
    letter-spacing: 1px;
    margin-bottom: 15px;
    transition: color 0.3s ease;
}

/* Paragraph Styling */
.subject-box p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #666666; /* Darker text for paragraphs */
    margin-top: 10px;
}

/* Subject Info Section */
.subject-box .subject-info {
    font-size: 1rem;
    font-weight: 500;
    color: #333333;
    margin-bottom: 15px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.subject-box .subject-info:hover {
    color: #d8b5ff; /* Lighter purple on hover */
}

/* Subject Code and Credits Box */
.subject-box .subject-code,
.subject-box .credits-box {
    font-size: 0.9rem;
    color: #f7f7f7;
    background: #6a52db; /* Deep blue-purple */
    padding: 8px 16px;
    border-radius: 20px;
    margin-top: 10px;
    display: inline-block;
    transition: background 0.3s ease;
}

.subject-box .subject-code:hover,
.subject-box .credits-box:hover {
    background: #9c4fff; /* Lighter purple on hover */
}

/* Button Styling */
.subject-box button {
    background: linear-gradient(to right, #9c4fff, #6a52db); /* Gradient background */
    color: #f7f7f7;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1.2rem;
    padding: 14px 30px;
    border-radius: 50px;
    margin-top: auto;
    box-shadow: 0 6px 18px rgba(156, 79, 255, 0.4);
}

button:hover {
    box-shadow: 0 8px 20px rgba(156, 79, 255, 0.6);
}

/* Responsive Design */
@media (max-width: 768px) {
    .subjects h2 {
        font-size: 3rem;
    }

    .subject-box {
        height: 320px;
    }

    .subject-box h3 {
        font-size: 1.9rem;
    }

    .subject-box button {
        font-size: 1.1rem;
    }
}

@media (max-width: 480px) {
    .subjects h2 {
        font-size: 2.6rem;
    }

    .subjects ul {
        grid-template-columns: 1fr;
    }

    .subject-box {
        height: 300px;
    }

    .subject-box h3 {
        font-size: 1.7rem;
    }
}

/* Animations */
@keyframes fadeIn {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
}
